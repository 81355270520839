import NextLink from 'next/link';
import React, { useCallback, useMemo } from 'react';

import { removeSpan } from '@/components/shared/utility/format';
import { CSS, styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const StyledLink = styled('a', {
  color: 'inherit',
  textDecoration: 'none',
  variants: {
    clickable: {
      true: {
        position: 'absolute',
        inset: '$space-0',
        fontSize: 0,
      },
    },
    hoverColor: {
      white: {
        '@hover': {
          '&:hover *': {
            color: '$white',
            '& span': {
              color: 'inherit',
            },
          },
        },
      },
      red: {
        '@hover': {
          '&:hover *': {
            color: '$primary',
            '& span': {
              color: 'inherit',
            },
          },
        },
      },
      gray: {
        '@hover': {
          '&:hover *': {
            color: '$gray400',
            '& span': {
              color: 'inherit',
            },
          },
        },
      },
    },
    underline: {
      true: {
        '& > *': {
          color: '$primary',
          textDecoration: 'underline',
          'text-underline-position': 'from-font',
        },
        '@hover': {
          '&:hover': {
            '& > *': {
              color: '$primary100',
            },
          },
        },
      },
    },
  },
});

interface LinkProps extends React.ComponentProps<typeof StyledLink> {
  css?: CSS;
  className?: string;
  children?: React.ReactNode;
  title?: string;
  href?: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  prefetch?: boolean;
  clickable?: boolean;
  hoverColor?: 'white' | 'red' | 'gray';
  underline?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  tracking?: ITracking;
  test?: ITest;
}

export const Link: React.FC<LinkProps> = React.memo(
  ({
    children,
    title,
    href,
    target,
    prefetch = false,
    clickable = false,
    hoverColor,
    underline = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
    tracking,
    test,
    css,
    className,
  }) => {
    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        if (typeof onClick === 'function') {
          onClick();
        }
      },
      [onClick]
    );

    const handleMouseEnter = useCallback(() => {
      if (typeof onMouseEnter === 'function') {
        onMouseEnter();
      }
    }, [onMouseEnter]);

    const handleMouseLeave = useCallback(() => {
      if (typeof onMouseLeave === 'function') {
        onMouseLeave();
      }
    }, [onMouseLeave]);

    const commonProps = useMemo(
      () => ({
        'aria-label': title,
        underline,
        hoverColor,
        clickable,
        'data-track': tracking?.dataTrack,
        'data-track-section': tracking?.dataTrackSection,
        'data-track-value': tracking?.dataTrackValue,
        'data-track-text': tracking?.dataTrackText
          ? removeSpan(tracking.dataTrackText)
          : undefined,
        'data-track-url': tracking?.dataTrackUrl,
        'data-test': test?.dataTest,
        'data-test-tag': test?.dataTestTag,
        'data-test-update-date': test?.dataTestUpdateDate,
        'data-test-pin': test?.dataTestPin,
        css,
        className,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }),
      [
        title,
        underline,
        hoverColor,
        clickable,
        tracking,
        test,
        css,
        className,
        handleMouseEnter,
        handleMouseLeave,
      ]
    );

    return href ? (
      <StyledLink
        as={NextLink}
        href={href}
        prefetch={prefetch}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        {...commonProps}
      >
        {children}
      </StyledLink>
    ) : (
      <StyledLink as="div" onClick={handleClick} {...commonProps}>
        {children}
      </StyledLink>
    );
  }
);

Link.displayName = 'TextLink';
