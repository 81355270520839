import React, { forwardRef, memo, ReactNode } from 'react';

import { CSS, styled } from '@/stitches.config';

const FlexStyle = styled('div', {
  display: 'flex',
  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      'row-reverse': {
        flexDirection: 'row-reverse',
      },
      column: {
        flexDirection: 'column',
      },
      'column-reverse': {
        flexDirection: 'column-reverse',
      },
    },
    wrap: {
      nowrap: {
        flexWrap: 'nowrap',
      },
      wrap: {
        flexWrap: 'wrap',
      },
      'wrap-reverse': {
        flexWrap: 'wrap-reverse',
      },
    },
    alignItems: {
      center: {
        alignItems: 'center',
      },
      start: {
        alignItems: 'flex-start',
      },
      end: {
        alignItems: 'flex-end',
      },
      baseline: {
        alignItems: 'baseline',
      },
      stretch: {
        alignItems: 'stretch',
      },
    },
    justifyContent: {
      center: {
        justifyContent: 'center',
      },
      start: {
        justifyContent: 'flex-start',
      },
      end: {
        justifyContent: 'flex-end',
      },
      around: {
        justifyContent: 'space-around',
      },
      between: {
        justifyContent: 'space-between',
      },
      evenly: {
        justifyContent: 'space-evenly',
      },
      stretch: {
        justifyContent: 'stretch',
      },
    },
    gap: {
      '0': {
        gap: '$space-0',
      },
      '1': {
        gap: '$space-1',
      },
      '2': {
        gap: '$space-2',
      },
      '3': {
        gap: '$space-3',
      },
      '4': {
        gap: '$space-4',
      },
      '5': {
        gap: '$space-5',
      },
      '6': {
        gap: '$space-6',
      },
      '7': {
        gap: '$space-7',
      },
      '8': {
        gap: '$space-8',
      },
      '9': {
        gap: '$space-9',
      },
      '10': {
        gap: '$space-10',
      },
      '12': {
        gap: '$space-12',
      },
      '14': {
        gap: '$space-14',
      },
      '16': {
        gap: '$space-16',
      },
      '18': {
        gap: '$space-18',
      },
      '20': {
        gap: '$space-20',
      },
    },
    columnGap: {
      '0': {
        columnGap: '$space-0',
      },
      '1': {
        columnGap: '$space-1',
      },
      '2': {
        columnGap: '$space-2',
      },
      '3': {
        columnGap: '$space-3',
      },
      '4': {
        columnGap: '$space-4',
      },
      '5': {
        columnGap: '$space-5',
      },
      '6': {
        columnGap: '$space-6',
      },
      '7': {
        columnGap: '$space-7',
      },
      '8': {
        columnGap: '$space-8',
      },
      '9': {
        columnGap: '$space-9',
      },
      '10': {
        columnGap: '$space-10',
      },
      '12': {
        columnGap: '$space-12',
      },
      '14': {
        columnGap: '$space-14',
      },
      '16': {
        columnGap: '$space-16',
      },
      '18': {
        columnGap: '$space-18',
      },
      '20': {
        columnGap: '$space-20',
      },
    },
    rowGap: {
      '0': {
        rowGap: '$space-0',
      },
      '1': {
        rowGap: '$space-1',
      },
      '2': {
        rowGap: '$space-2',
      },
      '3': {
        rowGap: '$space-3',
      },
      '4': {
        rowGap: '$space-4',
      },
      '5': {
        rowGap: '$space-5',
      },
      '6': {
        rowGap: '$space-6',
      },
      '7': {
        rowGap: '$space-7',
      },
      '8': {
        rowGap: '$space-8',
      },
      '9': {
        rowGap: '$space-9',
      },
      '10': {
        rowGap: '$space-10',
      },
      '12': {
        rowGap: '$space-12',
      },
      '14': {
        rowGap: '$space-14',
      },
      '16': {
        rowGap: '$space-16',
      },
      '18': {
        rowGap: '$space-18',
      },
      '20': {
        rowGap: '$space-20',
      },
    },
  },
  defaultVariants: {
    direction: 'row',
    wrap: 'nowrap',
    alignItems: 'stretch',
    justifyContent: 'start',
  },
});

interface FlexProps extends React.ComponentProps<typeof FlexStyle> {
  /**
   * The content of the flex component.
   */
  children: ReactNode;
  /**
   * Additional CSS styles to apply to the component.
   */
  css?: CSS;
  /**
   * Class name to apply to the component.
   */
  className?: string;
  /**
   * The HTML tag to use for the component.
   * @default "div"
   */
  as?: 'div' | 'li' | 'ol' | 'span' | 'ul';

  /**
   * The ARIA role of the component.
   */
  role?: string;
}

/**
 * Renders a FlexComponent using the provided props.
 *
 * @param children - The content of the flex component.
 * @param css - Additional CSS styles to apply to the component.
 * @param className - Class name to apply to the component.
 * @param as - The HTML tag to use for the component. Default is 'div'.
 * @param role - The ARIA role of the component.
 * @param rest - Additional props for the FlexComponent.
 * @param ref - Reference to the component.
 * @returns JSX element representing the FlexComponent.
 */
const FlexComponent = forwardRef<HTMLDivElement, FlexProps>(
  ({ children, css, className, as = 'div', role, ...rest }: FlexProps, ref) => {
    return (
      <FlexStyle
        as={as}
        role={role}
        css={css}
        className={className}
        ref={ref}
        {...rest}
      >
        {children}
      </FlexStyle>
    );
  }
);

FlexComponent.displayName = 'Flex';

export const Flex = memo(FlexComponent);
