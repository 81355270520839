import { ImageProps } from '@/components/shared/media/image/Image.props';

interface IData {
  price: number;
  label: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

export const X_SERIES_DATA: IData = {
  price: 763000,
  label: 'เอ็กซ์-ซีรี่ส์',
  url: '/isuzu-x-series',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltadbc7b00934f5ce6/65ac707ea933e51c1902763a/x-series.png',
    alt: 'x-series',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0ae8a893222ff3a9/x-series-brochure-2024.pdf',
  //NOTE: permanent link
};

export const V_CROSS_DATA: IData = {
  price: 937000,
  label: 'วี-ครอส 4x4',
  url: '/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt12769a814d704a7a/651f81807bedefbe0194d8c0/v-cross.png',
    alt: 'v-cross 4x4',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6816b2c0528120f2/v-cross-brochure-2024.pdf',
  //NOTE: permanent link
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 746000,
  label: 'ปิกอัพ 4 ประตู',
  url: '/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc418a09e066ba4d4/651f819a1d2dc3807bfba1c8/4-Door.png',
    alt: 'pickup 4-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf5f12576135cc3bf/4-door-brochure-2024.pdf',
  //NOTE: permanent link
};

export const PICKUP_2DOOR_DATA: IData = {
  price: 665000,
  label: 'ปิกอัพ 2 ประตู',
  url: '/isuzu-pick-up-2-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7e119782e8d98b24/651f81a6964d9b7a27c328df/2-Door.png',
    alt: 'pickup 2-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt065fad7ba124fda4/2-door-brochure-2024.pdf',
  //NOTE: permanent link
};

export const SPARK_DATA: IData = {
  price: 592000,
  label: 'สปาร์ค',
  url: '/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbeb996cb3211b381/651f81b4705ef3a03548b554/spark.png',
    alt: 'spark',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9e4c25361495fbaa/spark-brochure-2024.pdf',
  //NOTE: permanent link
};

export const MUX_DATA: IData = {
  price: 1184000,
  label: 'มิว-เอ็กซ์',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt539d963868473192/63d8b9a5ca59cf11374ced36/phantom-white-front.png',
    alt: 'mu-x',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb748a93277ad1be9/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
};

export const MUX_ULTIMATE_DATA: IData = {
  price: 1184000,
  label: 'มิว-เอ็กซ์',
  url: '/isuzu-mu-x?model=ultimate',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6cd60581a482ffa2/66694098371bca4188b8cf48/ultimate.png',
    alt: 'MU-X Ultimate',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb748a93277ad1be9/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
};

export const MUX_RS_DATA: IData = {
  price: 1659000,
  label: 'มิว-เอ็กซ์ อาร์เอส',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb3c905d047206833/66694073b00d39b961dfe211/rs.png',
    alt: 'MU-X RS',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb748a93277ad1be9/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
  // FOR SHOW A BADGE
  new: true,
};

export const X_SERIES_MENU: IMenu = {
  label: X_SERIES_DATA.label,
  description: `เริ่มต้น THB ${X_SERIES_DATA.price.toLocaleString()}`,
  url: X_SERIES_DATA.url,
  image: X_SERIES_DATA.image,
  brochure: X_SERIES_DATA.brochure,
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `เริ่มต้น THB ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `เริ่มต้น THB ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const PICKUP_2DOOR_MENU: IMenu = {
  label: PICKUP_2DOOR_DATA.label,
  description: `เริ่มต้น THB ${PICKUP_2DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_2DOOR_DATA.url,
  image: PICKUP_2DOOR_DATA.image,
  brochure: PICKUP_2DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `เริ่มต้น THB ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_MENU: IMenu = {
  label: MUX_DATA.label,
  description: `เริ่มต้น THB ${MUX_DATA.price.toLocaleString()}`,
  url: MUX_DATA.url,
  image: MUX_DATA.image,
  brochure: MUX_DATA.brochure,
  // FOR SHOW A BADGE
};

export const MUX_ULTIMATE_DATA_MENU: IMenu = {
  label: MUX_ULTIMATE_DATA.label,
  description: `เริ่มต้น THB ${MUX_ULTIMATE_DATA.price.toLocaleString()}`,
  url: MUX_ULTIMATE_DATA.url,
  image: MUX_ULTIMATE_DATA.image,
  brochure: MUX_ULTIMATE_DATA.brochure,
};

export const MUX_RS_DATA_MENU: IMenu = {
  label: MUX_RS_DATA.label,
  description: `เริ่มต้น THB ${MUX_RS_DATA.price.toLocaleString()}`,
  url: MUX_RS_DATA.url,
  image: MUX_RS_DATA.image,
  brochure: MUX_RS_DATA.brochure,
  // FOR SHOW A BADGE
  new: MUX_RS_DATA.new,
};
